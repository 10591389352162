<template>
  <div class="bg-white rounded-lg shadow-sm overflow-hidden">
    <div class="p-6">
      <!-- Current Subscription Section -->
      <div v-if="currentSubscription && currentSubscription.status !== 'inactive'" class="space-y-6">
        <div>
          <div class="flex items-center justify-between mb-6">
            <h2 class="text-2xl font-semibold text-gray-700">
              {{ trans('leakblock.subscription.current.title') }}
            </h2>
            <div class="px-3 py-1 rounded-full text-sm text-center"
                 :class="{
                   'bg-green-100 text-green-800': currentSubscription.status === 'active',
                   'bg-yellow-100 text-yellow-800': currentSubscription.status === 'active_until_period_end',
                   'bg-red-100 text-red-800': currentSubscription.status === 'canceled'
                 }"
            >
              {{ getStatusText(currentSubscription.status) }}
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="space-y-3">
              <div>
                <span class="text-sm font-medium text-gray-500">
                  {{ trans('leakblock.subscription.current.plan') }}
                </span>
                <p class="text-lg font-medium text-gray-900">
                  {{ currentPlanName }}
                </p>
              </div>
              <div>
                <span class="text-sm font-medium text-gray-500">
                  {{ trans('leakblock.subscription.billing.period') }}
                </span>
                <p class="text-lg font-medium text-gray-900">
                  {{ getBillingDisplayText }}
                </p>
              </div>
            </div>

            <div class="space-y-3">
              <div v-if="currentSubscription.status === 'active_until_period_end'">
                <span class="text-sm font-medium text-gray-500">
                  {{ trans('leakblock.subscription.cancel.endDate') }}
                </span>
                <p class="text-lg font-medium text-gray-900">
                  {{ formatDate(currentSubscription.currentPeriodEnd) }}
                </p>
              </div>
              <div v-else>
                <span class="text-sm font-medium text-gray-500">
                  {{ trans('leakblock.subscription.current.nextBilling') }}
                </span>
                <p class="text-lg font-medium text-gray-900">
                  {{ currentSubscription?.currentPeriodEnd ? formatDate(currentSubscription.currentPeriodEnd) : '-' }}
                </p>
              </div>

              <div v-if="getRemainingDays() > 0">
                <span class="text-sm font-medium text-gray-500">
                  {{ trans('leakblock.subscription.current.remaining') }}
                </span>
                <p class="text-lg font-medium text-gray-900">
                  {{ getRemainingDays() }} {{ trans('leakblock.subscription.current.days') }}
                </p>
              </div>
            </div>

            <!-- Show cancellation info if applicable -->
            <div v-if="currentSubscription.status === 'active_until_period_end'"
                 class="col-span-full bg-yellow-50 p-4 rounded-md">
              <p class="text-yellow-800">
                {{
                  trans('leakblock.subscription.cancel.pendingCancellation', {
                    date: formatDate(currentSubscription.currentPeriodEnd)
                  })
                }}
              </p>
              <button
                  @click="reactivateSubscription"
                  class="mt-2 text-sm font-medium text-yellow-800 hover:text-yellow-900 underline"
              >
                {{ trans('leakblock.subscription.cancel.reactivate') }}
              </button>
            </div>
          </div>
        </div>

        <!-- Upgrade Options -->
        <!--        <div v-if="currentSubscription?.status === 'active' && availableUpgrades.length > 0"-->
        <div v-if="1 !== 1" class="mt-6 border-t pt-6">
          <h3 class="text-lg font-medium text-gray-900 mb-4">
            {{ trans('leakblock.subscription.change.title') }}
          </h3>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div v-for="tier in availableUpgrades"
                 :key="tier.id"
                 class="border rounded-lg p-6 space-y-4"
                 :class="{
                       'border-primary-500 shadow-md': tier.isCurrentPlan,
                       'border-gray-200': !tier.isCurrentPlan
                      }"
            >
              <div class="space-y-2">
                <h4 class="text-xl font-bold">{{ tier.name }}</h4>
                <p class="text-gray-600">{{ tier.description }}</p>
                <p class="text-2xl font-bold">
                  €{{ tier.amount }}
                  <span class="text-sm font-normal text-gray-500">
                            {{ trans(`leakblock.subscription.billing.${currentBillingPeriod.value}`) }}
                          </span>
                </p>
              </div>
              <button
                  v-if="!tier.isCurrentPlan"
                  @click="changePlan(tier)"
                  class="w-full flex justify-center items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md font-medium"
                  :disabled="isLoading"
              >
                  <span v-if="isLoading" class="mr-2">
                    <svg class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                {{
                  isLoading
                      ? trans('leakblock.subscription.change.processing')
                      : trans('leakblock.subscription.change.upgrade.button')
                }}
              </button>
            </div>
          </div>
        </div>

        <!-- Cancel Subscription Section -->
        <div v-if="currentSubscription.status === 'active'" class="mt-6 border-t pt-6">
          <div class="flex flex-col space-y-4">
            <div>
              <h3 class="text-lg font-medium text-gray-900">
                {{ trans('leakblock.subscription.cancel.sectionTitle') }}
              </h3>
              <p class="text-sm text-gray-500">
                {{ trans('leakblock.subscription.cancel.description') }}
              </p>
            </div>
            <div class="flex justify-end md:justify-start">
              <button
                  @click="confirmCancellation"
                  class="w-full md:w-auto border border-primary-500 text-center flex items-center justify-center text-primary-500 hover:bg-gray-200 px-10 py-2 transition rounded-md self-center font-medium"
                  :disabled="isLoading"
              >
                <svg
                    v-if="isLoading"
                    class="animate-spin h-4 w-4 text-primary-500 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {{
                  isLoading ? trans('leakblock.subscription.cancel.processing') : trans('leakblock.subscription.cancel.button')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Subscription Tiers for New Users -->
      <div v-else>
        <h2 class="text-2xl font-semibold text-gray-700 mb-6">
          {{ trans('leakblock.subscription.choose.title') }}
        </h2>

        <!-- Add Period Selection -->
        <div class="mb-8">
          <div class="inline-flex items-center bg-gray-100 rounded-lg p-1">
            <button
                @click="selectedPeriod = 'monthly'"
                class="px-6 py-2 text-sm font-medium rounded-md transition-all duration-200"
                :class="[
                  selectedPeriod === 'monthly'
                    ? 'bg-white text-primary-600 shadow-sm'
                    : 'text-gray-600 hover:text-gray-900'
                ]"
            >
              {{ trans('leakblock.subscription.period.monthly') }}
            </button>
            <button
                @click="selectedPeriod = 'quarterly'"
                class="px-6 py-2 text-sm font-medium rounded-md transition-all duration-200 relative"
                :class="[
                  selectedPeriod === 'quarterly'
                    ? 'bg-white text-primary-600 shadow-sm'
                    : 'text-gray-600 hover:text-gray-900'
                ]"
            >
              {{ trans('leakblock.subscription.period.quarterly') }}
              <span class="absolute -top-2 -right-2 px-2 py-0.5 text-xs bg-green-100 text-green-800 rounded-full">
                -20%
              </span>
            </button>
          </div>
        </div>

        <div class="space-y-8">
          <!-- Custom pagination container -->
          <div class="flex justify-center">
            <div class="swiper-pagination-custom"></div>
          </div>

          <Swiper
              :slides-per-view="1.2"
              :space-between="16"
              :initial-slide="1"
              :centered-slides="true"
              :breakpoints="{
                '768': {
                  slidesPerView: 1.8,
                  spaceBetween: 32,
                }
              }"
                        :pagination="{
                el: '.swiper-pagination-custom',
                clickable: true,
                bulletActiveClass: 'bg-primary-500 w-8',
                bulletClass: 'inline-block h-2 w-2 rounded-full bg-gray-300 transition-all duration-200 mx-1'
              }"
              :navigation="true"
              :modules="[Pagination, Navigation]"
              class="subscription-swiper"
          >
            <SwiperSlide
                v-for="tier in subscriptionTiers"
                :key="tier.id"
                class="h-auto"
            >
              <div
                  class="subscription-card rounded-lg border p-6"
                  :class="[
          tier.recommended
            ? 'subscription-card-recommended border-primary-500 shadow-lg bg-white relative'
            : 'border-gray-200 bg-gray-50 hover:shadow-md'
        ]"
              >
                <!-- Popular Badge -->
                <div
                    v-if="tier.recommended"
                    class="absolute -top-3 left-1/2 -translate-x-1/2 bg-primary-500 text-white px-4 py-1 rounded-full text-sm font-medium whitespace-nowrap"
                >
                  {{ trans('leakblock.subscription.mostPopular') }}
                </div>

                <!-- Content wrapper -->
                <div class="space-y-6 flex-grow">
                  <!-- Header section -->
                  <div>
                    <h3 class="text-2xl font-bold">{{ tier.name }}</h3>
                    <p class="text-gray-600 mt-2">{{ tier.description }}</p>

                    <div class="mt-4">
                      <p class="text-4xl font-bold">
                        {{ tier.prices[selectedPeriod].amount.toFixed(2) }} €
                      </p>
                      <p>{{ trans(`leakblock.subscription.period.billing.${selectedPeriod}`) }}</p>
                      <p class="text-sm text-gray-500">
                <span class="text-sm font-normal text-gray-500">
                  + {{ trans('leakblock.subscription.period.vat') }}
                </span>
                      </p>
                    </div>
                  </div>

                  <!-- Features list -->
                  <div class="space-y-4 flex-grow">
                    <div v-for="(feature, index) in tier.features"
                         :key="index"
                         class="flex items-start gap-3">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           class="h-5 w-5 text-primary-500 flex-shrink-0 mt-1"
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-gray-700" v-html="feature"></span>
                    </div>
                  </div>
                </div>

                <!-- Button -->
                <button
                    @click="subscribe(tier.prices[selectedPeriod][currentLang])"
                    class="w-full flex justify-center items-center px-4 py-3 transition rounded-lg font-medium mt-6"
                    :class="[
            tier.recommended
              ? 'bg-primary-500 text-white hover:bg-primary-600'
              : 'bg-gray-800 text-white hover:bg-gray-900'
          ]"
                    :disabled="isLoading"
                >
          <span v-if="isLoading" class="mr-2">
            <svg class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
                  {{ isLoading ? trans('leakblock.subscription.subscribe.processing') : trans('leakblock.subscription.subscribe.button') }}
                </button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <!-- Add VAT notice -->
        <div class="text-center text-sm text-gray-500">
          {{ trans('leakblock.subscription.period.vatNotice') }}
        </div>
      </div>

      <Snackbar
          :message="snackbarMessage"
          :show="showSnackbar"
          :color="snackbarColor"
          @close="showSnackbar = false"
      />
    </div>
  </div>
</template>

<script setup>
import {ref, computed, onMounted, nextTick} from 'vue';
import axios from 'axios';
import Snackbar from '../../../components/Snackbar/Snackbar.vue';
import {trans} from "../../../../js/utilities/leakblock-translations";
import Swal from 'sweetalert2';
import {formatDate} from "../../../../base";
import {useRoute} from "vue-router";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Pagination, Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const route = useRoute();
const isLoading = ref(false);
const currentSubscription = ref(null);
const snackbarMessage = ref('');
const showSnackbar = ref(false);
const snackbarColor = ref('red');
const selectedPeriod = ref('monthly');
const currentLang = ref(route.params.lang || 'en');
const currentPlanName = ref(trans('leakblock.subscription.unknown'));
const billing = ref({
  period: null,
  intervalCount: null
});

const sharedModalConfig = {
  buttonsStyling: false,
  customClass: {
    popup: 'rounded-lg',
    title: 'text-2xl font-bold pb-4',
    htmlContainer: 'text-gray-600',
    confirmButton: 'w-full !ml-0 !mb-2 !border !border-primary-500 bg-primary-500 text-white px-10 py-2 rounded-md hover:!border-primary-400 hover:bg-primary-400 transition duration-150 font-medium',
    cancelButton: 'w-full !ml-0 bg-white !border !border-primary-500 text-center items-center text-primary-500 hover:bg-gray-200 px-10 py-2 transition rounded-md duration-150 font-medium',
    actions: 'flex flex-col sm:flex-row px-5'
  }
};

const getBillingDisplayText = computed(() => {
  if (billing.value.period === 'month') {
    return billing.value.intervalCount === 3
        ? trans('leakblock.subscription.period.billing.quarterly')
        : trans('leakblock.subscription.period.billing.monthly');
  }
  return trans('leakblock.subscription.period.billing.monthly');
});

const subscriptionTiers = [
  {
    id: 1,
    name: 'Starter',
    description: trans('leakblock.subscription.period.tiers.starter.description'),
    features: [
      trans('leakblock.subscription.period.tiers.starter.features.0'),
      trans('leakblock.subscription.period.tiers.starter.features.1'),
      trans('leakblock.subscription.period.tiers.starter.features.2'),
      trans('leakblock.subscription.period.tiers.starter.features.3'),
      trans('leakblock.subscription.period.tiers.starter.features.4')
    ],
    prices: {
      monthly: {
        amount: 149,
        de: 'price_1QYsfdG07xtnk7jtdt3L8yMS',
        en: 'price_1QYsfgG07xtnk7jtTynCfy3M'
      },
      quarterly: {
        amount: 119.20,
        de: 'price_1Qmc8AG07xtnk7jttAMBHsKA',
        en: 'price_1QYsfjG07xtnk7jtv6uQWtVN'
      }
    },
    recommended: false
  },
  {
    id: 2,
    name: 'Pro',
    description: trans('leakblock.subscription.period.tiers.pro.description'),
    features: [
      trans('leakblock.subscription.period.tiers.pro.features.0'),
      trans('leakblock.subscription.period.tiers.pro.features.1'),
      trans('leakblock.subscription.period.tiers.pro.features.2'),
      trans('leakblock.subscription.period.tiers.pro.features.3'),
      trans('leakblock.subscription.period.tiers.pro.features.4'),
      trans('leakblock.subscription.period.tiers.pro.features.5'),
      trans('leakblock.subscription.period.tiers.pro.features.6')
    ],
    prices: {
      monthly: {
        amount: 229,
        de: 'price_1Qmc9aG07xtnk7jtv5Ee5OyG',
        en: 'price_1QmcA5G07xtnk7jtdhnLKCER'
      },
      quarterly: {
        amount: 183.20,
        de: 'price_1QmcBRG07xtnk7jtN3aleBT1',
        en: 'price_1QYsfoG07xtnk7jtqWVKAluE'
      }
    },
    recommended: true
  },
  {
    id: 3,
    name: 'Ultimate',
    description: trans('leakblock.subscription.period.tiers.enterprise.description'),
    features: [
      trans('leakblock.subscription.period.tiers.enterprise.features.0'),
      trans('leakblock.subscription.period.tiers.enterprise.features.1'),
      trans('leakblock.subscription.period.tiers.enterprise.features.2'),
      trans('leakblock.subscription.period.tiers.enterprise.features.3'),
      trans('leakblock.subscription.period.tiers.enterprise.features.4'),
      trans('leakblock.subscription.period.tiers.enterprise.features.5'),
      trans('leakblock.subscription.period.tiers.enterprise.features.6'),
      trans('leakblock.subscription.period.tiers.enterprise.features.7')
    ],
    prices: {
      monthly: {
        amount: 599,
        de: 'price_1QYsfqG07xtnk7jt9Qe3viIr',
        en: 'price_1QYsfrG07xtnk7jttbpIqQmW'
      },
      quarterly: {
        amount: 479.20,
        de: 'price_1QYsfsG07xtnk7jtPqIUHAt7',
        en: 'price_1QYsftG07xtnk7jtjtj5By6m'
      }
    },
    recommended: false
  }
];

const currentBillingPeriod = computed(() => {
  if (!currentSubscription.value?.priceId) {
    return null;
  }
  return selectedPeriod.value;
});

const availableUpgrades = computed(() => {
  if (!currentSubscription.value?.priceId || !currentBillingPeriod.value) {
    console.log('No current subscription or billing period');
    return [];
  }

  // Find current tier and its price
  let currentTierIndex = -1;
  let currentTier = null;

  for (let i = 0; i < subscriptionTiers.length; i++) {
    const tier = subscriptionTiers[i];
    const tierPrices = tier.prices[currentBillingPeriod.value];

    if (tierPrices.de === currentSubscription.value.priceId ||
        tierPrices.en === currentSubscription.value.priceId) {
      currentTierIndex = i;
      currentTier = tier;
      break;
    }
  }

  // If no matching tier is found, show all tiers as upgrade options
  if (currentTierIndex === -1) {
    console.log('Current tier not found - showing all upgrade options');
    return subscriptionTiers.map(tier => ({
      ...tier,
      name: tier.name,
      description: tier.description,
      prices: tier.prices,
      priceId: tier.prices[currentBillingPeriod.value][currentLang.value],
      amount: tier.prices[currentBillingPeriod.value].amount,
      isCurrentPlan: false
    }));
  }

  // Otherwise, show only higher tiers
  return subscriptionTiers
      .slice(currentTierIndex + 1)
      .map(tier => ({
        ...tier,
        name: tier.name,
        description: tier.description,
        prices: tier.prices,
        priceId: tier.prices[currentBillingPeriod.value][currentLang.value],
        amount: tier.prices[currentBillingPeriod.value].amount,
        isCurrentPlan: false
      }));
});

const fetchSubscriptionStatus = async () => {
  try {
    const response = await axios.get('/api/subscription/status');
    currentSubscription.value = response.data;

    if (currentSubscription.value?.priceId) {
      // Get price details including interval information
      const priceDetails = await axios.get(`/api/subscription/price-details/${currentSubscription.value.priceId}`);
      billing.value = {
        period: priceDetails.data.interval,
        intervalCount: priceDetails.data.interval_count
      };
      currentPlanName.value = priceDetails.data.product_name;
      selectedPeriod.value = priceDetails.data.interval_count === 3 ? 'quarterly' : 'monthly';
    }
  } catch (error) {
    console.error('Error fetching subscription:', error);
    showSnackbarMessage(trans('leakblock.subscription.error.fetchStatus'));
  }
};

const getRemainingDays = () => {
  if (!currentSubscription.value?.currentPeriodEnd) return 0;

  try {
    const end = new Date(currentSubscription.value.currentPeriodEnd);
    if (isNaN(end.getTime())) return 0;

    const now = new Date();
    const diffTime = end.getTime() - now.getTime();
    return Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
  } catch (error) {
    console.error('Error calculating remaining days:', error);
    return 0;
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'active':
      return trans('leakblock.subscription.status.active');
    case 'active_until_period_end':
      return trans('leakblock.subscription.status.pendingCancellation');
    case 'canceled':
      return trans('leakblock.subscription.status.canceled');
    case 'past_due':
      return trans('leakblock.subscription.status.pastDue');
    default:
      return status;
  }
};

const getPlanName = async (priceId) => {
  // First try to find in our predefined tiers
  for (const tier of subscriptionTiers) {
    if (tier.prices.monthly.de === priceId ||
        tier.prices.monthly.en === priceId ||
        tier.prices.quarterly.de === priceId ||
        tier.prices.quarterly.en === priceId) {
      return tier.name;
    }
  }

  try {
    const response = await axios.get(`/api/subscription/price-details/${priceId}`);
    return response.data.product_name || trans('leakblock.subscription.unknown');
  } catch (error) {
    console.error('Error fetching price details:', error);
    return trans('leakblock.subscription.unknown');
  }
};

const showSnackbarMessage = (message, color = 'red') => {
  snackbarMessage.value = message;
  snackbarColor.value = color;
  showSnackbar.value = true;
};

const confirmCancellation = async () => {
  const result = await Swal.fire({
    title: trans('leakblock.subscription.cancel.confirmTitle'),
    html: `
      <div class="space-y-6 text-center">
        <p class="text-lg">${trans('leakblock.subscription.cancel.confirmText', {
      date: formatDate(currentSubscription.value.currentPeriodEnd)
    })}</p>
      </div>
    `,
    showCancelButton: true,
    confirmButtonText: trans('leakblock.subscription.cancel.confirm'),
    cancelButtonText: trans('leakblock.subscription.cancel.back'),
    ...sharedModalConfig,
    width: '36rem',
  });

  if (result.isConfirmed) {
    await cancelSubscription();
  }
};

const cancelSubscription = async () => {
  isLoading.value = true;
  try {
    await axios.post('/api/subscription/cancel');
    showSnackbarMessage(trans('leakblock.subscription.cancel.success'), 'green');
    await fetchSubscriptionStatus();
  } catch (error) {
    console.error('Cancellation error:', error);
    showSnackbarMessage(trans('leakblock.subscription.cancel.error'));
  } finally {
    isLoading.value = false;
  }
};

const reactivateSubscription = async () => {
  isLoading.value = true;
  try {
    await axios.post('/api/subscription/reactivate');
    showSnackbarMessage(trans('leakblock.subscription.reactivate.success'), 'green');
    await fetchSubscriptionStatus();
  } catch (error) {
    console.error('Reactivation error:', error);
    showSnackbarMessage(trans('leakblock.subscription.reactivate.error'));
  } finally {
    isLoading.value = false;
  }
};

const subscribe = async (priceId) => {
  isLoading.value = true;
  try {
    const response = await axios.post('/api/create-checkout-session', {
      priceId: priceId,
      period: selectedPeriod.value
    });

    if (response.data.url) {
      window.location.href = response.data.url;
    }
  } catch (error) {
    console.error('Subscription error:', error);
    showSnackbarMessage(trans('leakblock.subscription.error.checkout'));
  } finally {
    isLoading.value = false;
  }
};

const changePlan = async (tier) => {
  try {
    if (!currentBillingPeriod.value) {
      throw new Error('No billing period found');
    }

    const currentPriceDetails = await axios.get(`/api/subscription/price-details/${currentSubscription.value.priceId}`);
    const newPriceId = tier.prices[currentBillingPeriod.value][currentLang.value];

    if (currentSubscription.value.priceId === newPriceId) {
      showSnackbarMessage(trans('leakblock.subscription.change.same_plan'));
      return;
    }

    // Get proration details from Stripe
    const proration = await calculateProratedAmount(newPriceId);
    const isQuarterly = currentPriceDetails.data.interval === 'month' &&
        currentPriceDetails.data.interval_count === 3;

    // Format monetary values using Stripe's calculations
    const formattedAmounts = {
      immediate: proration.immediate_amount.toFixed(2),
      nextBill: proration.next_invoice_amount.toFixed(2),
      tax: proration.tax.toFixed(2),
      total: proration.total_including_tax.toFixed(2),
      currentPrice: (currentPriceDetails.data.amount / 100).toFixed(2),
      newPrice: tier.prices[currentBillingPeriod.value].amount.toFixed(2)
    };

    const result = await Swal.fire({
      title: trans('leakblock.subscription.change.upgrade.title'),
      html: `
        <div class="text-left space-y-4">
          <div class="space-y-2">
            <div class="flex flex-col gap-2">
              <div class="p-4 bg-gray-50 rounded-lg">
                <span class="text-sm text-gray-500 block">${trans('leakblock.subscription.change.current', {
        plan: `${currentPriceDetails.data.product_name || trans('leakblock.subscription.unknown')}${
            isQuarterly ? ' (' + trans('leakblock.subscription.billing.quarterly') + ')' : ''
        }`,
        price: formattedAmounts.currentPrice
      })}</span>
              </div>
              <div class="p-4 bg-primary-500 rounded-lg">
                <span class="text-sm text-white block">${trans('leakblock.subscription.change.new', {
        plan: `${tier.name}${
            isQuarterly ? ' (' + trans('leakblock.subscription.billing.quarterly') + ')' : ''
        }`,
        price: formattedAmounts.newPrice
      })} ${trans('leakblock.subscription.per_month')}</span>
              </div>
            </div>
          </div>

          <div class="border-t border-b py-4">
            <div class="mb-2">
              <p class="font-semibold text-lg text-gray-900">
                ${trans('leakblock.subscription.change.upgrade.immediate')}
              </p>
              <p class="text-sm text-gray-600">
                ${trans('leakblock.subscription.change.upgrade.immediateInfo')}
              </p>
            </div>
            <div class="space-y-2">
              <div class="flex justify-between">
                <span class="text-gray-600 text-sm">${trans('leakblock.subscription.change.upgrade.subtotal', {
        amount: formattedAmounts.immediate
      })}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-600 text-sm">${trans('leakblock.subscription.change.upgrade.tax', {
        rate: proration.tax_rate,
        amount: formattedAmounts.tax
      })}</span>
              </div>
              <div class="flex justify-between pt-4 border-t">
                <span class="font-bold text-gray-900">${trans('leakblock.subscription.change.upgrade.total', {
        amount: formattedAmounts.total
      })}</span>
              </div>
            </div>
          </div>

          <div class="py-1 text-sm">
            ${trans('leakblock.subscription.change.nextBill', {
        date: formatDate(proration.nextBillingDate),
        amount: formattedAmounts.nextBill
      })}
          </div>

          <div class="pt-2 border-t">
            <p class="text-sm text-gray-500">
              ${trans('leakblock.subscription.change.upgrade.legalText')}
            </p>
          </div>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: trans('leakblock.subscription.change.confirm'),
      cancelButtonText: trans('leakblock.subscription.change.cancel'),
      ...sharedModalConfig,
      width: '36rem',
    });

    if (result.isConfirmed) {
      isLoading.value = true;
      try {
        await axios.post('/api/subscription/change-plan', {
          newPriceId: newPriceId
        });
        showSnackbarMessage(trans('leakblock.subscription.change.success'), 'green');
        await fetchSubscriptionStatus();
      } catch (error) {
        console.error('Plan change error:', error);
        showSnackbarMessage(
            error.response?.data?.error ||
            trans('leakblock.subscription.change.error'),
            'red'
        );
      } finally {
        isLoading.value = false;
      }
    }
  } catch (error) {
    if (error.response?.data?.type === 'downgrade_not_allowed') {
      showSnackbarMessage(trans('leakblock.subscription.change.upgrade_only'));
    } else {
      console.error('Plan change error:', error);
      showSnackbarMessage(trans('leakblock.subscription.change.error'));
    }
  }
};

const calculateProratedAmount = async (newPriceId) => {
  try {
    const response = await axios.post('/api/subscription/calculate-proration', {
      newPriceId: newPriceId
    });
    return response.data;
  } catch (error) {
    console.error('Proration calculation error:', error);
    throw error;
  }
};

onMounted(async () => {
  await fetchSubscriptionStatus();
});
</script>
<style>
.subscription-swiper {
  padding-bottom: 2rem !important;
}

/* Ensure wrapper and slides maintain height */
.subscription-swiper .swiper-wrapper {
  height: auto !important;
  align-items: stretch;
  padding-top: 1rem;
}

.subscription-swiper .swiper-slide {
  height: auto !important;
  display: flex;
}

/* Custom Pagination Styles */
.swiper-pagination-custom {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
}

/* Navigation Arrows */
.subscription-swiper .swiper-button-next,
.subscription-swiper .swiper-button-prev {
  color: theme('colors.primary.500');
  background: white;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.subscription-swiper .swiper-button-next::after,
.subscription-swiper .swiper-button-prev::after {
  font-size: 1.25rem;
  font-weight: bold;
}

.subscription-swiper .swiper-button-disabled {
  opacity: 0.5;
}

/* Card styles */
.subscription-card {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  transition-property: box-shadow, background-color, border-color;
}

.subscription-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.subscription-card-features {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.subscription-swiper .swiper-button-next {
  right: 0rem;
}

.subscription-swiper .swiper-button-prev {
  left: 0rem;
}

/* Desktop styles */
@media (min-width: 768px) {
  .subscription-swiper {
    margin-left: -4rem;
    margin-right: -4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .subscription-swiper .swiper-button-next,
  .subscription-swiper .swiper-button-prev {
    display: flex;
  }

  .subscription-swiper .swiper-button-next {
    right: 3rem;
  }

  .subscription-swiper .swiper-button-prev {
    left: 3rem;
  }
}
</style>