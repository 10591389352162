<template>
  <div class="rounded-md lb-shadow xl:col-span-1 md:overflow-hidden relative min-h-[460px] md:max-h-[700px]">
    <h3 class="flex items-center text-lg font-semibold relative bg-white px-6 py-4 rounded-t-md">
      {{ trans('leakblock.leakStatusStatistics.title') }}
    </h3>
    <div id="fullWidthTabContent" class="px-3 md:px-6 pb-[60px] md:pb-[90px] h-[calc(100%-60px)]">
      <div v-if="isLoading" class="flex justify-center items-center h-full">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
      <div v-else-if="sortedLeakStatistics.length > 0" class="h-full">
        <div class="mb-6">
          <Pie
              :data="chartData"
              :options="chartOptions"
          />
        </div>
        <div class="mt-4 h-1/2 overflow-y-auto">
          <h4 class="text-lg font-semibold mb-2">{{ trans('leakblock.leakStatusStatistics.statusOverview') }}</h4>
          <ul class="space-y-2">
            <li v-for="(status, index) in sortedLeakStatistics" :key="index" class="flex items-center justify-between">
              <div class="flex items-center">
                <div
                    class="w-4 h-4 rounded-full mr-2"
                    :style="{ backgroundColor: status.bgColor }"
                ></div>
                <span>{{ trans(status.name) }}</span>
              </div>
              <span class="font-semibold">{{ status.value }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="flex justify-center items-center h-full">
        <p class="text-center text-gray-500 py-4">{{ trans('leakblock.leakStatusStatistics.noDataAvailable') }}</p>
      </div>
    </div>
    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[60px] border-t border-gray-200 rounded-b-md px-6 items-center">
      <div class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500">
        {{ trans('leakblock.leakStatusStatistics.totalLeaks', { count: totalLeaks }) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axios from 'axios';
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend } from 'chart.js';
import { trans } from "../../../../js/utilities/leakblock-translations";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

const props = defineProps({
  accountUrl: {
    type: String,
    required: true
  },
  isFreeTier: {
    type: Boolean,
    default: false
  }
});

const leakStatistics = ref([]);
const isLoading = ref(true);

const sortedLeakStatistics = computed(() => {
  const sortOrder = [
    'leakblock.status.found',
    'leakblock.status.found_self',
    'leakblock.status.in_progress',
    'leakblock.status.action_required',
    'leakblock.status.completed'
  ];

  const sorted = [...leakStatistics.value].sort((a, b) => {
    const indexA = sortOrder.indexOf(a.name);
    const indexB = sortOrder.indexOf(b.name);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  return sorted;
});

const chartData = computed(() => ({
  labels: sortedLeakStatistics.value.map(status => trans(status.name)),
  datasets: [{
    backgroundColor: sortedLeakStatistics.value.map(status => status.bgColor),
    data: sortedLeakStatistics.value.map(status => status.value)
  }]
}));

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false
};

const totalLeaks = computed(() => {
  return leakStatistics.value.reduce((sum, status) => sum + status.value, 0);
});

const options = {
  scrollbars: {
    autoHide: 'scroll'
  }
};

const fetchLeakStatistics = async () => {
  isLoading.value = true;
  try {
    if (props.isFreeTier) {
      const response = await axios.get('/build/demo/leak-statistics.json');
      leakStatistics.value = response.data;
    } else {
      const response = await axios.get(`/api/leak-statistics?account_url=${props.accountUrl}`);
      leakStatistics.value = response.data;
    }
  } catch (error) {
    console.error('Error fetching leak statistics:', error);
    leakStatistics.value = [];
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchLeakStatistics();
});

watch(() => props.accountUrl, fetchLeakStatistics);
</script>